
import DetailCard from '@/components/Common/DetailCard';
import ManagementMixin from '@/mixins/ManagementMixin';

export default {
  components: {
    DetailCard
  },
  mixins: [ManagementMixin]
};
